html,
body {
    height: 100% !important;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}

.css-1pnmrwp-MuiTypography-root {
    font-family: 'Century Gothic Paneuropean', sans-serif !important;
}

body {
    background-color: #ffffff !important;
    font-family: 'Century Gothic Paneuropean', sans-serif !important;
}

#root {
    height: 100% !important;
}

.App div:first-child() {
    height: 100% !important;
}

/*Custom ScrollBar Css*/
/* width */
::-webkit-scrollbar {
    width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #757575;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/*Custom ScrollBar Css*/
.mainFrame {
    /* width: 385px !important; */
    /* height: 812px; */
    /* border: 1px solid #eeeeee; */
    /* margin: 0 auto; */
    width: auto;
    height: 620px;
    margin: 0;
    overflow-x: hidden;
}

/* Root CSS */
:root {
    --themeColor: transparent;
    --filledButtonTextColor: transparent;
    --selectionButtonBGColor: transparent;
}

.frameHeadGetStarted {
    /* background-color: #3E87CB !important; */
    background-color: var(--themeColor) !important;
}

.frameBody {
    background-color: #ffffff;
    margin-top: -40px;
    border-top-left-radius: 40px;
    /* height: calc(100% - 240px); */
    /* height: calc(100% - 160px); */
    overflow-y: visible;
}

.primaryBtn {
    /* background-color: #3E87CB !important; */
    background-color: var(--themeColor) !important;
    color: var(--filledButtonTextColor) !important;
    box-shadow: none !important;
    border: 0px !important;
    text-transform: none !important;
    ;
}

.secondaryBtn {
    border: 1px solid var(--themeColor) !important;
    color: var(--themeColor) !important;
}

.underline {
    text-decoration: underline;
}

.WidgetHeaderComponent {
    display: flex;
    flex-direction: row;
    padding: 6px 0px;
}

.brandLogo {
    display: flex;
    flex-grow: 1;
}

.boldText {
    font-weight: 600;
}

.closeWidget {
    color: #ffffff !important;
}

.primaryColor {
    color: #3E87CB !important;
}

.frameHead {
    /* height: 180px; */
    height: 86px;
    /* background-color: #3E87CB !important; */
    background-color: var(--themeColor) !important;
}

.mainTitle {
    font-size: 28px !important;
    font-weight: 600 !important;
    line-height: 42px !important;
}

.secondaryTitle {
    font-size: 16px !important;
    color: #212121 !important;
    text-align: center;
    padding: 18px 0px !important;
}

.commonInput {
    border: 0px !important;
    border: 1px solid #E3E3E3 !important;
    border-radius: 6px !important;
    margin-bottom: 32px !important;
    padding: 4px !important;
    font-weight: 500 !important;
}

.commonInput .MuiInputBase-formControl {
    border: 0px !important;
    margin-top: 0px;
    outline: 0;
}

.commonInput label {
    position: absolute;
    top: -18px;
}

.commonInput .icon {
    color: #E6E6E6;
}

.topTabNavigation {
    display: flex;
    justify-content: center;
    margin: 10px 0px 20px;
}

.topTabNavigation .tabButton {
    margin: 0px 8px;
}

.tabButton .tabAction {
    box-shadow: none;
}

.commonInput .MuiInputBase-formControl:hover,
.commonInput .MuiInputBase-formControl::before {
    border: 0px !important;
}

.footerActions {
    align-items: end;
    /* background-color: #fff; */
    bottom: 0px;
    display: flex;
    height: 36px;
    justify-content: end;
    /* padding: 12px 0; */
    padding: 10px 0;
    position: fixed;
    /* width: 370px; */
    right: 20px;
}

.required {
    color: #e94b44;
}

.calendarBox {
    border: 1px;
    border-color: #f8f8f8;
    border-radius: 4%;
    background-color: #ffffff;
}

.bottom-border {
    border-bottom: 1px dotted #aeaeae;
    margin: 4px 10px;
    /* margin-top: 4%; */
    /* borderBottom: '2px dashed #aeaeae', marginTop: 2 */
}

.noServiceArea {
    text-align: center;
    padding-top: 30px;
}

.scheduleDateTime {
    font-size: 14px !important;
    padding: 4px 0;
    color: #212121 !important;
}

.moxieSchedule .calendarBox .MuiYearCalendar-root {
    width: 270px !important;
    max-height: 200px !important;
}

.inspScheduled {
    color: #333;
    margin-bottom: 15px;
    background: #3e87cb0f;
    padding: 10px 30px;
    border-radius: 25px;
}

.noServiceArea .scheduleTxt {
    font-size: 16px;
    font-weight: 600;
}

.scheduleData {
    width: 260px !important;
}

.noServiceArea .areaText {
    box-shadow: none;
    border: 1px dotted #000;
    margin: 28px;
    font-weight: 600 !important;
}

.sectionSpacing {
    padding: 20px 0px;
}

.callBackRequest {
    margin: 0 auto !important;
    width: 300px !important;
}

.callBackRequest h4 {
    margin: 0;
    padding-bottom: 12px;
    font-size: 25px;
}

.callBackRequest p {
    margin: 0;
    margin-top: 10px;
    font-size: 14px;
    line-height: 21px;
    color: #212121 !important;
}

.commonSelectError {
    margin: 3px 0px 0px !important;
}

.noteText {
    font-size: 12px !important;
    /* color: #3e87cb; */
    color: var(--themeColor);
    margin: 0px 10px 4px !important;
    text-align: left !important;
}

.checkBoxLabel {
    font-size: 10px !important;
}

.notificationBox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.commonInputSelect .MuiMenu-list {
    font-size: 13px !important;
    padding: 0px !important;
}

.commonInputSelect .MuiMenuItem-root.MuiMenuItem-gutters {
    font-size: 13px !important;
    padding: 0px 10px !important;
}

.propDetails {
    width: 270px;
    margin: 0 auto;
}

.propDetails .MuiList-padding {
    padding: 0px !important;
}

.propDetails .MuiButtonBase-root.MuiMenuItem-root {
    font-size: 13px !important;
}

.textMessage .MuiInputBase-input {
    font-size: 13px !important;
}

/* .noServiceArea .areaText span:nth-child(1){
    position: absolute;
    left: -12px;
    top: 50%;
    transform: translateY(-50%) rotate(-130deg);
    width: 15px;
    height: 15px;
    border-left: 2px dashed #000;
    border-bottom: 2px dashed #000;
    background: #fff;
}

.noServiceArea .areaText span:nth-child(2){
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%) rotate(130deg);
    width: 15px;
    height: 15px;
    border-left: 2px dashed #000;
    border-bottom: 2px dashed #000;
    background: #fff;
} */

@media only screen and (max-width: 600px) and (min-width: 280px) {
    .commonDescription {
        font-size: 14px !important;
    }

}

@media only screen and (max-width: 2600px) and (min-width: 1600px) {
    .mainFrame {
        height: 720px
    }

    .frameBody {
        height: calc(100% - 180px);
    }
}




/* Calendar Css */

.moxieSchedule .MuiPickersDay-root.MuiPickersDay-dayWithMargin {
    width: 28px !important;
    height: 30px !important;
    margin: 0px 6px !important;
}

Mui-selected,
Mui-selected:hover {
    background-color: var(--themeColor) !important;
}

.moxieSchedule .calendarBox {
    height: 245px !important;
    border: 1px solid #e1e1e1;
    border-radius: 0px;
    width: 270px;
    margin: 0px 6px auto;
}

.moxieSchedule .MuiDayCalendar-weekDayLabel {
    height: 30px !important;
}

.moxieSchedule .MuiDateCalendar-root {
    width: auto;
}

.moxieSchedule .MuiPickersCalendarHeader-root {
    padding-left: 56px;
}

/* 03-09-2024 */
.scheduleBtnActive {
    padding: 4px !important;
    font-size: 10px !important;
    background-color: var(--selectionButtonBGColor) !important;
    color: var(--filledButtonTextColor) !important;
}

.scheduleBtnInactive {
    padding: 4px !important;
    font-size: 10px !important;
    background-color: #f2f2f2 !important;
    color: #887b7b !important;
}

@media only screen and (max-width: 600px) and (min-width: 280px) {
    .moxieSchedule .MuiPickersDay-root.MuiPickersDay-dayWithMargin {
        height: 25px !important;
        width: 25px !important;
    }

    .moxieSchedule .MuiDayCalendar-weekDayLabel {
        width: 34px;
    }

    /* 03-09-2024 */
    /* .scheduleBtn {
        padding: 4px !important;
        font-size: 10px !important;
    } */

    .scheduleBtnActive {
        padding: 4px !important;
        font-size: 10px !important;
        background-color: var(--selectionButtonBGColor) !important;
        color: var(--filledButtonTextColor) !important;
    }

    .scheduleBtnInactive {
        padding: 4px !important;
        font-size: 10px !important;
        background-color: #f2f2f2 !important;
        color: #887b7b !important;
    }


    /* .noteText
    {
        font-size: 10px !important;
    } */
    .checkBoxLabel {
        font-size: 11px !important;
        position: relative;
        top: 2px;
    }

    .moxieSchedule .MuiPickersCalendarHeader-root {
        padding-left: 16px;
    }
}

/* Calendar Css */


.notificationBox {
    /* width: 300px !important; */
    margin: 12px auto 0 !important;
    width: 90% !important;
    border-radius: 14px !important;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16) !important;
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16) !important;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16) !important;
}

.notificationBox h6 {
    font-size: 14px;
    color: #212121 !important;
}

/* .loaderOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loaderOverlay img {
    width: 100px;
    height: 100px;
} */

.loaderOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

/* .loader {
    border: 8px solid #f3f3f3; 
    border-top: 8px solid #3e87cb; 
    border-radius: 50%; 
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
} */

/* HTML: <div class="loader"></div> */
.loader {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 4px solid;
    /* Reduced border thickness */
    border-color: var(--themeColor) transparent;
    /* Changed color to blue */
    animation: l1 1s linear infinite;
    /* Continuous rotation without intervals */
}

@keyframes l1 {
    to {
        transform: rotate(1turn);
        /* Full rotation for continuous spin */
    }
}


.calendarBoxFields .MuiCheckbox-root {
    padding: 5px;
}

.noTimesWork {
    margin-top: 0 !important;
}

.linkColor {
    color: #3E87CB !important;
}

.boldText {
    font-weight: 600;
}

.previewImage {
    max-width: 160px;
}

.primaryBtn,
.tabButton .tabAction,
.moxieAppHeading,
.moxieAppHeading,
.moxieSchedule {
    font-family: 'Century Gothic Paneuropean', sans-serif !important;
}

@media only screen and (max-width: 600px) and (min-width: 280px) {
    .mainTitle {
        font-size: 22px !important;
        line-height: 32px !important;
    }

    .getStartedTopSpacing {
        padding-top: 20px !important;
    }
}

.moxieSchedule .MuiPickersCalendarHeader-root {
    margin-top: 4px;
}

.locationPart {
    position: relative;
    margin-bottom: 20px;
    z-index: 99;
}

.locationPartIcon {
    position: absolute;
    top: 21px;
    right: 7px;
    background-color: #fff;
    color: #666;
    width: 32px;
}

.css-lttt06-MuiButtonBase-root-MuiButton-root {
    background-color: #d3e2f0 !important;
    color: var(--themeColor) !important;
}

.css-15sl1p4-MuiButtonBase-root-MuiButton-root {
    color: var(--themeColor) !important;
    background-color: var(--selectionButtonBGColor) !important;
}

/* Define the Home/Commercial buttons active and inactive styles */
.tabButtonActive {
    background-color: var(--selectionButtonBGColor) !important;
    color: var(--filledButtonTextColor) !important;
}

.tabButtonInactive {
    background-color: #f2f2f2 !important;
    color: #887b7b !important;
}

/* 03-09-2024 */
.tabAction.tabButtonActive:hover {
    color: var(--filledButtonTextColor) !important;
}

.Mui-checked {
    color: var(--themeColor) !important;
}

/* Border color on hover */
.customInput .MuiOutlinedInput-root:hover fieldset {
    border-color: var(--themeColor) !important;

}

/* Border color when focused */
.customInput .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: var(--themeColor) !important;
}

.MuiPickersDay-root.Mui-selected {
    background-color: var(--themeColor) !important;
    color: var(--filledButtonTextColor) !important;
}