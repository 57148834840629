.frameBody .css-1yutbqp{
    padding: 0;
}
.moxieSchedule
{
    /* border: 1.5px dotted #aeaeae; */
    border-radius: 12px;
    padding: 0px;
}
.scheduleTitle
{
    padding: 6px !important;
    padding-left: 12px !important;
    font-size: 13px !important;
}
.calendarBoxFields
{
    width: 270px;
}
.timeslotError
{
    font-size: 18px;
}