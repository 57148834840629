.textField {
    margin-bottom: 30px;
}

.required {
    color: red;
}

.propDetails .commonInput {
    border: 0 !important;
    padding: 0 !important;
}

.propDetails .commonInput label p {
    color: #333;
}

.propDetails .MuiFormControl-root {
    margin-bottom: 0;
}

.propDetails .MuiInputBase-formControl,
.propDetails .MuiInputBase-formControl:hover {
    /* border: 1px solid #E3E3E3 !important; */
    border-radius: 4px !important;
}

.propDetails .css-1km1ehz.Mui-selected {
    font-size: 13px;
}

.propDetails .customInput label p {
    font-size: 13px;
    /* margin-top: 5px; */
}

.propDetails .commonSelectError {
    font-size: 10px;
    padding-left: 10px;
}

.propDetails .customInput .Mui-error {
    font-size: 10px;
    padding-left: 0px;
    line-height: 10px;
}

.moxieAppHeading {
    padding: 8px 0 0px 0 !important;
}

.propDetails .customInput {
    margin-bottom: 22px;
    width: 100%;
}

.propDetails .customInput input,
.propDetails .customInput .MuiSelect-select {
    height: 24px;
    padding: 5px 10px;
    font-size: 13px;
    color: #333;
}

/* .propDetails .customInput label{
    -webkit-transform: translate(14px, 8px) scale(1);
    transform: translate(14px, 8px) scale(1);
    -moz-transform: translate(14px, 8px) scale(1);
} */

.propDetails .customInput .MuiInputLabel-outlined {
    top: -8px;
    font-size: 13px;
    line-height: 12px;
}

.inspectionCost {
    margin-top: -12px;
}

.inspectionCost span {
    padding: 0px;
    position: relative;
    top: -6px;
}

.inspectionCostCheckboxLabel {
    font-size: 12px !important;
    line-height: 13px !important;
    padding-left: 5px;
}

.zipCodeError {
    color: #d32f2f;
    height: 24px !important;
    padding: 5px 10px;
    font-size: 13px;
}


/* CustomTextField.css */

/* Default label color */
/* .customInput .MuiInputLabel-root {
    color: blue !important; 
} */

.customInput .MuiInputLabel-root.Mui-focused {
    color: var(--themeColor) !important;
    /* Label color when focused */
}

/* Default border color */
/* .customInput .MuiOutlinedInput-root fieldset {
    border-color: red !important; 
} */

/* Border color on hover */
/* .customInput .MuiOutlinedInput-root:hover fieldset {
    border-color: var(--themeColor) !important;
} */

/* Border color when focused */
/* .customInput .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: var(--themeColor) !important;
    
} */